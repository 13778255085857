import { NextPageContext } from 'next';
import nextCookie from 'next-cookies';
import { NextRouter } from 'next/dist/next-server/lib/router/router';
import { LOGIN_REDIRECT_URL_KEY } from 'src/redux/modules/auth/sagas';
import { retrieveUser } from 'src/redux/modules/user/operations';
import { getLocale } from '../locales';
import { AuthenticatedProfile } from '../redux/modules/auth/interfaces';
import { doRetrieveUser } from '../redux/modules/user/actions';
import { User } from '../redux/modules/user/interfaces';
import { AppState } from '../redux/reducers';

export const redirect = ({ res }: NextPageContext, route: string): void => {
  if (res) {
    res.writeHead(302, { Location: route }).end();
  } else {
    window.location.assign(route);
  }
};

export const redirectOnboardedUser = async (ctx: NextPageContext) => {
  const { accessToken } = nextCookie(ctx);
  if (!accessToken) {
    return;
  }

  try {
    const locale = getLocale(ctx);
    const { data } = await retrieveUser(accessToken, locale);
    if (data.result?.onboarded) {
      redirect(ctx, `/${locale}/cockpit`);
    }
  } finally {
    // since we are only interested in redirecting the user as long
    // as he is onboarded we just ignore any errors
    return;
  }
};

export const optionalAuth = (ctx: NextPageContext, skipRedirect?: boolean) => {
  const { authProfileJSON, accessToken, tokenPayloadJSON } = nextCookie(ctx);
  const { pathname, store } = ctx;
  const state: AppState = store.getState();
  const locale = getLocale(ctx);

  let authProfile: AuthenticatedProfile | undefined;

  if (authProfileJSON) {
    authProfile = JSON.parse(decodeURIComponent(authProfileJSON));
  }

  if (authProfile && accessToken) {
    authProfile = { ...authProfile, accessToken: decodeURIComponent(accessToken) };
  }

  if (authProfile && tokenPayloadJSON) {
    authProfile = { ...authProfile, tokenPayload: JSON.parse(decodeURIComponent(tokenPayloadJSON)) };
  }

  const redirectUser = (user: User) => {
    if (!skipRedirect) {
      if (!user) {
        redirect(ctx, `/${locale}/login`);
      } else if (!user.onboarded && user.type === 'full') {
        redirect(ctx, `/${locale}/signup/inactive`);
      } else if (!user.onboarded && user.type === 'light' && !isPath('EVENT', pathname)) {
        redirect(ctx, `/${locale}/events`);
      }
    }
  };

  if (state?.userReducer.user) {
    redirectUser(state.userReducer.user);
  } else if (!pathname.includes('/signup')) {
    store.dispatch(
      doRetrieveUser(redirectUser, (_, parsedError) => {
        if (parsedError?.status === 404) {
          redirect(ctx, `/${locale}/signup/about`);
        }
      })
    );
  }

  return authProfile;
};

export const isPath = (category: 'EVENT' | 'ARTICLE' | 'MARKET', pathname?: string) => {
  if (!pathname) {
    return false;
  }

  const path = decodeURIComponent(pathname);

  switch (category) {
    case 'EVENT':
      return path.includes('/event/') || path.endsWith('/events') || path.endsWith('/my-events');
    case 'ARTICLE':
      return path.includes('/article/') || path.endsWith('/articles');
    case 'MARKET':
      return path.includes('/markets/');
  }
};

export const requiredAuth = (ctx: NextPageContext, skipRedirect?: boolean) => {
  const authProfile = optionalAuth(ctx, skipRedirect);
  const { query } = ctx;
  const locale = getLocale(ctx);

  if (!authProfile?.tokenPayload || authProfile.tokenPayload.exp < Date.now() / 1000) {
    const { asPath: path = '/' } = ctx;
    const ccgidParam = query.ccgid ? `&ccgid=${query.ccgid}` : '';
    const resumeParam = typeof query.resume !== 'undefined' ? `&resume=${query.resume}` : '';
    const redirectUrl =
      path && path !== '/'
        ? `?redirectUrl=${encodeURIComponent(
            `/${locale}${path.replace(/(&|)(ccgid|resume)=.*?(&|$|#)/, '')}`
          )}${ccgidParam}${resumeParam}`
        : `?${ccgidParam}`;
    redirect(ctx, `/${locale}/join${redirectUrl}`);

    return;
  }

  // Resume requested? Redirect to resume location.
  if (
    typeof query.resume !== 'undefined' &&
    authProfile &&
    authProfile['https://goglobal.s-ge.com/ggc_continuation_url']
  ) {
    redirect(ctx, authProfile['https://goglobal.s-ge.com/ggc_continuation_url'].replace(/[&?]resume([&=]|$)/, ''));
    return;
  }

  return authProfile;
};

export const parameterAnchor2HashAnchor = (url: string) => {
  return url.replace('?anchor=', '#').replace('&anchor=', '#');
};

//Redirects to redirectUrl and removes it from the local storage
export const redirectUserToRedirectUrl = (router: NextRouter, locale: string, defaultPath = 'cockpit') => {
  const redirectUrl = window.localStorage.getItem(LOGIN_REDIRECT_URL_KEY);
  if (redirectUrl) {
    if (redirectUrl.indexOf('/') == -1) {
      router.push(`/${locale}/` + parameterAnchor2HashAnchor(redirectUrl));
    } else {
      router.push(parameterAnchor2HashAnchor(redirectUrl));
    }
    window.localStorage.removeItem(LOGIN_REDIRECT_URL_KEY);
  } else {
    router.push(`/${locale}/` + defaultPath);
  }
};
