import { Industry } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const RETRIEVE_INDUSTRIES = 'industries/RETRIEVE_INDUSTRIES';
export const RETRIEVE_INDUSTRIES_SUCCESS = 'industries/RETRIEVE_INDUSTRIES_SUCCESS';
export const RETRIEVE_INDUSTRIES_FAILURE = 'industries/RETRIEVE_INDUSTRIES_FAILURE';

export interface RetrieveIndustriesAction {
  type: typeof RETRIEVE_INDUSTRIES;
  language?: string;
}

export interface RetrieveIndustriesSuccessAction {
  type: typeof RETRIEVE_INDUSTRIES_SUCCESS;
  industries: Industry[];
}

export interface RetrieveIndustriesFailureAction {
  type: typeof RETRIEVE_INDUSTRIES_FAILURE;
  error?: RequestResult<void>;
}
