import axios from 'axios';
import getConfig from 'next/config';
import { User } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

const { publicRuntimeConfig } = getConfig();

export function createUser(user: User, accessToken: string, locale: string) {
  return axios.post<RequestResult<User>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users`, user, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function certifyUser(user: User, accessToken: string, locale: string) {
  return axios.post<RequestResult<User>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users/login`, user, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function retrieveUser(accessToken: string, locale: string) {
  return axios.get<RequestResult<User>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function syncDBUser(accessToken: string, locale: string) {
  return axios.get<RequestResult<User>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users/local-sync`, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}

export function updateUser(user: User, industries: number | string[] | null, accessToken: string, locale: string) {
  const payload: { user: User; industries?: number | string[] } = { user };
  if (industries) {
    payload.industries = industries;
  }
  return axios.put<RequestResult<User>>(`${publicRuntimeConfig.API_URI}/v1/${locale}/users`, payload, {
    headers: { Authorization: `Bearer ${accessToken}` }
  });
}
