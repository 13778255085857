import {
  RETRIEVE_INDUSTRIES,
  RETRIEVE_INDUSTRIES_FAILURE,
  RETRIEVE_INDUSTRIES_SUCCESS,
  RetrieveIndustriesAction,
  RetrieveIndustriesFailureAction,
  RetrieveIndustriesSuccessAction
} from './types';
import { Industry } from './interfaces';
import { RequestResult } from '../../../interfaces/requests';

export const doRetrieveIndustries = (language?: string): RetrieveIndustriesAction => ({
  type: RETRIEVE_INDUSTRIES,
  language: language
});

export const doRetrieveIndustriesFailure = (error?: RequestResult<void>): RetrieveIndustriesFailureAction => ({
  error,
  type: RETRIEVE_INDUSTRIES_FAILURE
});

export const doRetrieveIndustriesSuccess = (industries: Industry[]): RetrieveIndustriesSuccessAction => ({
  type: RETRIEVE_INDUSTRIES_SUCCESS,
  industries
});
